<template>
  <div class="container">
<!--    邀请好友来赚钱-->
    <div class="outer">
      <div class="invite">
        <div class="title">{{$t('rebate.rebate_title')}}</div>
        <div class="title-sub">{{$t('rebate.rebate_title_sub')}}</div>
        <div class="grade">
          <div style="vertical-align: middle">{{$t('rebate.user_grade')}}</div>
          <div style="cursor: pointer" @click="spreadDialogVisible=true">
            <div v-if="!userInfo.vip_level && userInfo.vip_level != 0" style="color: #673007;">{{ $t('rebate.ordinary_users') }}</div>
            <div v-else class="lv" style="display: inline-block;margin-top: 0; vertical-align: middle">
              <span class="SF_medium" style="font-size: 14px;color: #673007;position: absolute;left: calc(50% - 4px);vertical-align: middle;">Lv{{userInfo.vip_level}}</span>
            </div>
            <span style="font-size: 14px; color: #AB54DB; vertical-align: middle; margin-left: 5px" @click="spreadDialogVisible=true">{{ $t('rebate.rule') }}</span>
          </div>
          <div>{{$t('rebate.user_superior')}}</div>
          <div style="vertical-align: middle;line-height: 21px" v-if="userInfo.parent_invitation!=null">{{userInfo.parent_invitation}}</div>
          <div v-else @click="setSuperiorDialogVisible=true" style="cursor: pointer">{{$t('rebate.set_superior')}}</div>
        </div>
<!--        <div style="margin: -10px 0 20px 0;color:#1E2329;">-->
<!--          <span style="margin-right: 40px;color: #72777E">{{$t('rebate.drive_one')}}{{ invitedPct }}</span>-->
<!--          <span>{{$t('rebate.drive_two_1')}}<span style="color: #72777E">{{teamPct}}</span></span>-->
<!--        </div>-->
        <div class="border-rebate"></div>
        <div class="extend">
          <div>{{$t('rebate.user_invite_code')}} <span>{{userInfo.invitation}}</span><img @click="handleCopyCode" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt=""></div>
          <div><div>{{$t('rebate.spread_link')}} </div><span> {{userInfo.reg_url}}</span><img @click="handleCopyLink" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt=""></div>
          <div class="poster"><span @click="handleCratePoster">{{$t('rebate.create_poster')}}</span><span><img @click="handleCratePoster" src="../../assets/img/rebate/qr_code.svg" alt="" style="width: 32px;height: 32px"></span></div>
        </div>
        <div class="picture">
          <img src="../../assets/img/rebate/pic_rebate.svg" alt="">
        </div>
      </div>
    </div>
    <div style="background: #FFFFFF" class="outer">
      <div>
        <!--    功能-->
        <div class="icons">
          <div>
            <div><img src="../../assets/img/rebate/assets.svg" alt=""><span>{{$t('rebate.capital_account')}}</span></div>
            <div><span>{{ userInfo.asset_balance | roundTwoFilter}}</span>USDT</div>
            <div>
              <span id="topUpBtn1" @click="getTopUpInfo">{{$t('rebate.top_up')}}</span>
              <span class="tixian" v-if="userInfo.tixian_state==2" @click="showTixian">{{$t('rebate.withdraw')}}</span>
              <span class="tixian" v-if="userInfo.tixian_state==1" style="cursor: not-allowed;background: rgba(44, 117, 255, 0.15);color: rgb(114, 119, 126);border: 1px solid rgb(207, 217, 236)">{{$t('rebate.withdraw_of')}}</span>
              <span @click="handleTransfer(1)">{{$t('rebate.transfer')}}</span>
            </div>
          </div>
          <div>
            <div style="display: block;" :style="{ marginBottom: userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3?'8px':'24px' }">
              <div style="display: block"><img style="vertical-align: middle" src="../../assets/img/rebate/trade.svg" alt=""><span style="vertical-align: middle;font-size: 20px; font-weight: 500; color: #1E2329; margin-left: 12px;">{{$t('rebate.trading_account')}}</span></div>
              <img style="display: block;width: unset;height: unset;margin-left: 60px" v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" src="../../assets/img/user/discount.svg">
            </div>

            <div><span>{{ userInfo.deposit | roundTwoFilter}}</span>USDT</div>
            <div>
<!--              <span id="topUpBtn" @click="getTopUpInfo">{{$t('rebate.top_up')}}</span>-->
              <span style="width: 100%;margin-right: 0" @click="handleTransfer(2)">{{$t('rebate.transfer')}}</span>
            </div>
          </div>
          <div>
            <div><img src="../../assets/img/rebate/award.svg" alt=""><span>{{$t('rebate.rebate_account')}}</span></div>
            <div><span>{{ userInfo.commission_balance | roundTwoFilter}}</span>USDT</div>
            <div @click="handleTransfer(3)">{{$t('rebate.transfer')}}</div>
          </div>
          <div>
            <div><img src="../../assets/img/rebate/performance.svg" alt=""><span>{{$t('rebate.total_performance')}}</span></div>
            <div><span>{{userInfo.recommend_money===null ? 0 :userInfo.recommend_money | roundTwoFilter}}</span>USDT</div>
            <div class="current-date">{{$t('rebate.until')}} {{currentDate}}</div>
          </div>
        </div>
        <!--    我的直推-->
        <div class="recommend">
          <div class="title"><img src="../../assets/img/rebate/recommend.svg" alt=""><span>{{$t('rebate.user_direct')}}</span><span>{{$t('rebate.common')}}{{recommendListTotal}}{{$t('rebate.person')}}</span></div>
          <div class="table">
            <el-table
                :size="isMini"
                :data="recommendList"
                ref="table"
                style="width: 100%">
              <el-table-column
                  prop="invitation"
                  :label="$t('rebate.invite_code')"
                  min-width="80px">
              </el-table-column>
              <el-table-column
                  prop="username"
                  min-width="130px"
                  :label="$t('rebate.user')">
                <template slot-scope="scope">
                  <el-input v-if="scope.row.showInput == true" v-focus v-model="scope.row.tagLin" @blur="onInputBlur(scope.row.invitation, scope.row.tagLin, scope.$index, scope.row)"></el-input>
                  <div v-else style="padding-top: 8px;padding-bottom: 8px;cursor: pointer" @click="showInputEdit(scope.$index, scope.row)">
                    <span>{{ scope.row.tag?scope.row.tag:scope.row.username }}</span>
                    <img style="vertical-align: middle;margin-left: 6px;cursor: pointer" src="../../assets/img/safe/alter_icon.svg">
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="deposit"
                  sortable
                  min-width="90px"
                  :label="$t('rebate.fuel_fee')">
                <template slot-scope="scope">
                  <span :style="{'color': Number(scope.row.deposit)<0 ? '#F56C6C' : ''}">{{scope.row.deposit?scope.row.deposit.toFixed(2):'0.00'}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="robot_state"
                  min-width="110px"
                  :label="$t('rebate.robot_state')">
                <template slot-scope="scope">
                  <span :style="{'color': scope.row.robot_state=='已关闭' ? 'red' : 'rgb(21, 216, 156)'}">{{scope.row.robot_state}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="vip_level"
                  sortable
                  min-width="100px"
                  :label="$t('rebate.vip_level')">
              </el-table-column>

              <el-table-column
                  prop="invitation_count"
                  sortable
                  min-width="100px"
                  :label="$t('rebate.recommend_count')">
              </el-table-column>
              <el-table-column
                  prop="recommend_money"
                  sortable
                  min-width="90px"
                  :label="$t('rebate.total_performance1')">
                <template slot-scope="scope">
                  {{scope.row.recommend_money.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="charge_style2"
                  min-width="100px"
                  :label="$t('rebate.member_type')">
              </el-table-column>
              <el-table-column
                  prop="create_time"
                  sortable
                  min-width="145px"
                  :label="$t('rebate.register_date')">
              </el-table-column>
              <el-table-column
                  prop="robot_update_time"
                  min-width="134px"
                  :label="$t('rebate.robot_change_time')">
                <template slot-scope="scope">
                  <span>{{scope.row.robot_update_time == null ? '-' : scope.row.robot_update_time }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                style="text-align: right;margin: 24px"
                layout="prev, pager, next"
                @current-change="handleRecommendCurrentChange"
                :page-size="10"
                :hide-on-single-page="recommendListTotal<=10"
                :total="recommendListTotal">
            </el-pagination>
          </div>
        </div>
        <!--    本月奖励记录-->
        <div class="award">
          <div class="title"><img src="../../assets/img/rebate/award.svg" alt=""><span>{{ $t('rebate.reward_record_of_this_month') }}</span></div>
          <div class="table">
            <el-table
                :size="isMini"
                :data="awardList"
                style="width: 100%">
              <el-table-column
                  prop="give_invitation"
                  :label="$t('rebate.invite_code')"
                  min-width="70px">
              </el-table-column>
              <el-table-column
                  prop="time1"
                  min-width="140px"
                  :label="$t('rebate.award_date')"
                  sortable>
              </el-table-column>
              <el-table-column
                  prop="commission_money"
                  sortable
                  min-width="136px"
                  :label="$t('rebate.award_money')">
              </el-table-column>
              <el-table-column
                  prop="service_money"
                  sortable
                  min-width="136px"
                  :label="$t('rebate.billing_profit')">
              </el-table-column>
<!--              <el-table-column-->
<!--                  prop="commission_id"-->
<!--                  min-width="80px"-->
<!--                  label="ID">-->
<!--              </el-table-column>-->
            </el-table>
            <el-pagination
                style="text-align: right;margin: 24px"
                layout="prev, pager, next"
                @current-change="handleAwardCurrentChange"
                :page-size="10"
                :hide-on-single-page="recommendListTotal<=10"
                :total="awardListTotal">
            </el-pagination>
          </div>
        </div>
        <!--    本月资金记录-->
<!--        <div class="capital">-->
<!--          <div class="title"><img src="../../assets/img/rebate/assets.svg" alt=""><span>本月資產紀錄</span>-->
<!--&lt;!&ndash;            <span style="font-size: 12px;margin-left:12px;padding-left: 12px">{{capitalRecord}}</span>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="table">-->
<!--            <el-table-->
<!--                :size="isMini"-->
<!--                :data="capitalList"-->
<!--                style="width: 100%">-->
<!--              <el-table-column-->
<!--                  prop="time_delete"-->
<!--                  :label="$t('rebate.write_date')"-->
<!--                  min-width="152px"-->
<!--                  sortable>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="profit"-->
<!--                  sortable-->
<!--                  min-width="144px"-->
<!--                  :label="$t('rebate.single_profit')">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="deposit_change"-->
<!--                  sortable-->
<!--                  min-width="154px"-->
<!--                  :label="$t('rebate.property_change')">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="deposit_balance"-->
<!--                  sortable-->
<!--                  min-width="144px"-->
<!--                  :label="$t('rebate.property_balance')">-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="style"-->
<!--                  sortable-->
<!--                  min-width="132px"-->
<!--                  :label="$t('rebate.change_type')">-->
<!--                <template slot-scope="scope">-->
<!--                  <span v-if="scope.row.style==1">{{$t('rebate.top_up')}}</span>-->
<!--                  <span v-else-if="scope.row.style==2">{{$t('rebate.bonus_cut_in')}}</span>-->
<!--                  <span v-else-if="scope.row.style==3">{{$t('rebate.withdraw')}}</span>-->
<!--                  <span v-else>扣费</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="id"-->
<!--                  min-width="80px"-->
<!--                  label="ID">-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--            <el-pagination-->
<!--                style="text-align: right;margin: 24px"-->
<!--                layout="prev, pager, next"-->
<!--                @current-change="handleCapitalCurrentChange"-->
<!--                :page-size="10"-->
<!--                :hide-on-single-page="recommendListTotal<=10"-->
<!--                :total="capitalListTotal">-->
<!--            </el-pagination>-->
<!--          </div>-->
<!--        </div>-->
        <div style="height: 1px"></div>
      </div>
    </div>

<!--    轉至資產成功对话框-->
    <el-dialog
        :visible.sync="transferDialogVisible"
        width="318px"
        center
        :close-on-click-modal="false"
        height="304px">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

<!--    推广海报二维码弹框-->
    <el-dialog
        class="poster-img"
        :show-close="false"
        :visible.sync="posterDialogVisible"
        width="318px"
        center
        height="304px">
      <div style="background: #FFFFFF;padding: 32px 17px 24px 17px;position: relative">
        <div style="position: absolute;top: 24px;right: 17px;"><img style="width: 16px;height: 16px;cursor: pointer" @click="posterDialogVisible=false" src="../../assets/img/close.svg" alt=""></div>
        <img src="../../assets/img/Felton.svg" alt="">
        <div style="font-size: 16px;font-weight: 400;color: #72777E;margin: 8px 0 30px 0">{{$t('rebate.poster_title')}}</div>
        <img :src="qr_code_url" alt="" style="width: 238px;height: 238px;margin: 0 0 39px 0">
        <div style=" position: relative;text-align: center;display: flex;justify-content:center;flex-wrap:wrap;  padding-top: 24px;border-top: 1px dashed #AB54DB;">
          <div style=" width: 100%;font-size: 14px;font-weight: 400;color: rgba(0, 0, 0, 0.85);margin-bottom: 12px">{{$t('rebate.spread_id')}}</div>
          <div style="font-size: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.85);">{{userInfo.invitation}}</div>
          <div id="btn-down" style="width: 80%;height:40px;line-height: 40px;margin-top:24px;border: 1px solid #AB54DB;font-size: 14px;font-weight: 400;color: #AB54DB;cursor: pointer"  @click="handleDownload">{{$t('rebate.download_poster')}}</div>
          <div style="width: 16px;height: 16px;background: #AB54DB;border-radius: 50%;position: absolute;left: -25px;top: -8px"></div>
          <div style="width: 16px;height: 16px;background: #AB54DB;border-radius: 50%;position: absolute;right: -25px;top: -8px"></div>
        </div>
      </div>
    </el-dialog>
<!--    充值对话框-->
<!--    <el-dialog-->
<!--        :visible.sync="topUpDialogVisible"-->
<!--        width="510px"-->
<!--        title="资产充值"-->
<!--        class="topUpDialog"-->
<!--        :close-on-click-modal="false"-->
<!--        height="304px">-->
<!--      <div class="topUp">-->
<!--        <div>主网</div>-->
<!--        <div><el-input disabled placeholder="TRC20"></el-input></div>-->
<!--        <div>钱包地址（此地址只可接收USDT）</div>-->
<!--        <div><img :src="topUpInfo.qrCode" alt="" style="max-width: 130px"></div>-->
<!--        <div>-->
<!--          <span>{{topUpInfo.base58Address}}</span><img @click="handleCopyAddress" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt="">-->
<!--        </div>-->
<!--        <div>重要提示：</div>-->
<!--        <div>-->
<!--          <ul>-->
<!--            <li>最少充值金额为50USDT</li>-->
<!--            <li>请仔细确认地址，充错无法找回</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" style="width: 100%"  @click="topUpDialogVisible=false">确定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
<!--提现对话框-->
    <el-dialog
        :visible.sync="withdrawDialogVisible"
        width="510px"
        :title="$t('rebate.property_withdraw')"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <el-form :model="withdrawForm" :rules="withdrawFormRules" ref="withdrawFormRef">
        <el-form-item :label="$t('rebate.withdraw_money')" prop="money" style="position: relative">
          <el-input @input="checkinput($event)" v-model="withdrawForm.money"></el-input>
          <div style="font-size: 14px;color: #B0B5BA;position: absolute;top: 41px;right: 66px">USDT</div>
          <div style=" width:1px;height:20px;position: absolute;top: 50px;right: 54px;border-right:1px solid #EAECEF;"></div>
          <div @click="handleMoneyAll" style="font-size: 14px;font-weight: 400;color: #AB54DB;position: absolute;top: 40px;right: 12px;cursor: pointer">{{$t('rebate.all')}}</div>
        </el-form-item>
        <el-form-item :label="$t('rebate.withdraw_address')" prop="address"><span style="color: #FF4D4F; margin-left: -10px">{{$t('rebate.TRC20_address')}}</span>
          <el-input v-model="withdrawForm.address"></el-input>
        </el-form-item>
<!--        <div  style="font-size: 12px;margin-top: 20px">重要提示:</div>-->
<!--        <div>-->
<!--          <ul style="padding-left: 20px;margin: 5px 0">-->
<!--            <li>提现手续费为1USDT，从提现金额里直接扣除</li>-->
<!--          </ul>-->
<!--        </div>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="handleWithdraw">{{$t('rebate.submit_apply')}}</el-button>
      </span>
    </el-dialog>
<!--    提現確認對話框   -->
    <el-dialog
        width="510px"
        :visible.sync="confirmWithdrawDialogVisible"
        :title="$t('rebate.property_withdraw_confirm')"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <div>{{$t('rebate.withdraw_money')}}: {{withdrawForm.money}}USDT</div>
      <div style="margin: 24px 0 20px 0">{{$t('rebate.withdraw_address')}}: {{withdrawForm.address}}</div>
      <el-form :model="withdrawForm" :rules="withdrawFormRules" ref="withdrawFormRef">
        <el-form-item :label="$t('rebate.email_auth_code')" prop="phone_auth_code" style="position: relative">
          <el-input v-model="withdrawForm.phone_auth_code"></el-input>
          <div v-if="userInfo.username!=null&&userInfo.username!=''" style="position: absolute;left: 83px;top: 0;">({{userInfo.username.slice(0,3)}} <span style="vertical-align: middle">****</span> {{userInfo.username.slice(7)}})</div>
          <div class="auth-code">
            <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{$t('rebate.send_auth_code')}}</div>
            <div v-else><span>{{phoneSeconds}}</span><span>{{$t('rebate.regain')}}</span></div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="handleWithdraw('confirm')">{{$t('rebate.confirm_withdraw')}}</el-button>
      </span>
    </el-dialog>

<!--    設置上級的對話框-->
    <el-dialog
        width="510px"
        :visible.sync="setSuperiorDialogVisible"
        :title="$t('rebate.set_superior')"
        class="topUpDialog"
        height="304px">
      <div>
        <el-input v-model="setSurperior" style="margin: 20px 0"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
<!--        <el-button  @click="setSurperior=false">取消</el-button>-->
        <el-button type="primary" style="width: 100%"   @click="handleSetSuperior">{{$t('rebate.set')}}</el-button>
      </span>
    </el-dialog>

<!--    返佣规则-->
    <el-dialog
        width="375px"
        :visible.sync="spreadDialogVisible"
        class="spreadDialogVisible"
        height="304px">
      <div style="position: relative;margin-top: 30px">
        <span style="width: 100%; display: block; background: rgba(44, 117, 255, 0.05); text-align: center; color: #1E2329; font-size: 16px; padding: 10px 0; border-radius: 8px 8px 0 0;">奖励方案</span>
        <el-table style="border-radius: 0 0 8px 8px; margin-top: 0px;" :data="ruleData"  border>
          <el-table-column fixed prop="lv" width="50px" label="级别"></el-table-column>
          <el-table-column fixed prop="describe" label="升级条件"></el-table-column>
          <el-table-column fixed prop="directPush" width="63px" label="奖励"></el-table-column>
<!--          <el-table-column fixed prop="interpulsion" width="63px" label="间推奖"></el-table-column>-->
        </el-table>
        <div style="margin-top: 10px;">
          <span style="display: block; font-size: 12px;color: #333333;">注意事项：</span>
<!--          <span style="display: block; font-size: 12px;color: #333333;margin-top: 15px;">普通用户升级到Lv0后进入分销系统，规则里的直推和间推用户都指Lv0以上用户</span>-->
          <span style="display: block; font-size: 12px;color: #333333;margin-top: 5px;">普通用户升级到Lv0后进入奖励系统，规则里的直推用户指Lv0以上用户</span>
          <span style="display: block; font-size: 12px;color: #333333;margin-top: 5px;">Lv2、Lv3、Lv4分别有1个月的级别保护期，Lv5、Lv6分别有3个月的级别保护期</span>
        </div>
        <span style="width: 100%; display: block; background: rgba(44, 117, 255, 0.05); text-align: center; color: #1E2329; font-size: 16px; padding: 10px 0; border-radius: 8px 8px 0 0; margin-top: 20px">经纪商奖励方案</span>
        <el-table style="border-radius: 0 0 8px 8px; margin-top: 0px;" :data="ruleEconomicQuotient"  border>
          <el-table-column fixed prop="lv" width="50px" label="级别"></el-table-column>
          <el-table-column fixed prop="describe" label="升级条件"></el-table-column>
          <el-table-column fixed prop="directPush" width="63px" label="全渠道奖励"></el-table-column>
          <!--          <el-table-column fixed prop="interpulsion" width="63px" label="间推奖"></el-table-column>-->
        </el-table>
        <div style="margin-top: 10px;">
          <span style="display: block; font-size: 12px;color: #333333;">注意事项：</span>
          <!--          <span style="display: block; font-size: 12px;color: #333333;margin-top: 15px;">普通用户升级到Lv0后进入分销系统，规则里的直推和间推用户都指Lv0以上用户</span>-->
          <span style="display: block; font-size: 12px;color: #333333;margin-top: 5px;">Lv4以上可以申请成为经纪商，详情请咨询客服。</span>
        </div>


<!--        <img style="width: 100%; height: 100%" src="../../assets/img/rebate/rebate_sp3.png" alt="">-->
<!--        <div @click="spreadDialogShowHandleDownload" style="position: absolute;left: calc(50% - 60px);bottom: 48px;-->
<!--width: 120px;-->
<!--color: #FFFFFF;-->
<!--cursor: pointer;-->
<!--text-align: center;-->
<!--line-height: 32px;-->
<!--height: 32px;-->
<!--border-radius: 4px;-->
<!--border: 1px solid #FFFFFF;-->
<!--">{{$t('rebate.download_img')}}</div>-->
      </div>
    </el-dialog>


<!--    下载的海报-->
    <div id="capture" style="position: absolute;right: 1000000px;top: 0;width: 274px;height: 506px;background: #AB54DB;padding: 24px">
      <div style="background: #FFFFFF;padding: 32px 17px 24px 17px;width: 240px">
        <img src="../../assets/img/Felton.svg" alt="">
        <div style="font-size: 16px;font-weight: 400;color: #72777E;margin: 8px 0 30px 0">{{$t('rebate.poster_title')}}</div>
        <img :src="qr_code_url" alt="" style="width: 238px;height: 238px;margin: 0 0 39px 0">
        <div style=" position: relative;text-align: center;display: flex;justify-content:center;flex-wrap:wrap;  padding-top: 24px;border-top: 1px dashed #AB54DB;">
          <div style=" width: 100%;font-size: 14px;font-weight: 400;color: rgba(0, 0, 0, 0.85);margin-bottom: 12px">{{$t('rebate.spread_id')}}</div>
          <div style="font-size: 24px;font-weight: 500;color: rgba(0, 0, 0, 0.85);">{{userInfo.invitation}}</div>
          <div style="width: 16px;height: 16px;background: #AB54DB;border-radius: 50%;position: absolute;left: -25px;top: -8px"></div>
          <div style="width: 16px;height: 16px;background: #AB54DB;border-radius: 50%;position: absolute;right: -25px;top: -8px"></div>
        </div>
      </div>
    </div>
    <img src="../../assets/img/rebate/rebate_sp3.png" id="capture2" style="position: absolute;right: 1000000px;top: 0;">

    <top_up ref="top_up" :userInfo="userInfo"></top_up>
    <transfer @updateUserInfo="getUserInfo" ref="transfer"></transfer>
  </div>
</template>

<script>
import {
  getAwardList,
  getCapitalList,
  getQrCode,
  getRecommendList,
  getUserInfo, setRecommendUserTag, setSuperior,
  transferProperty, userWithdraw, userWithdrawNew
} from "../../server/rebate";
import axios from "axios";
import {
  checkEmail,
  checkWithdrawalAddress,
  checkEmailGeneral,
  checkMobile,
  checkMobileGeneral,
  checkTwoPlace,
  checkTwoPlaceGeneral
} from "../../utils/validate";
import {getAuthCode} from "../../server/auth";
import {topUp} from "../../mixin/mixin";
import {transferMixin} from "../../mixin/mixin";
import top_up from "./top_up";
import transfer from "../user/transfer";
export default {
  name: "Rebate",
  data(){
    return{
      isMini:'mini',
      transferDialogVisible:false,
      spreadDialogVisible:false,
      //table表格
      recommendList: [],
      recommendListTotal:10,
      awardList:[],
      awardListTotal:10,
      capitalList:[],
      capitalListTotal:10,
      //  用户信息
      userInfo:{
        invitation:'', //邀请码
        parent_invitation:'', //上级邀请码
        vip_level:'',     //VIP等级
        //  佣金  保证金 总业绩
        commission_balance:'',
        deposit:'',
        recommend_money:'',
        tixian_state:2
      },
      posterDialogVisible:false,
      qr_code_url:'',
      topUpDialogVisible:false,  //充值对话框
      topUpInfo:{
        base58Address:'',
        qrCode:''
      },
      withdrawDialogVisible:false,  //提现对话框
      confirmWithdrawDialogVisible:false, //確認提現對話框
      withdrawForm:{
        money:'',
        address:'',
        phone_auth_code:''
      },
      withdrawFormRules:{
        money: [
          { required: true, message: this.$t('rebate.please_input_withdraw_money'), trigger: 'blur' },
          {validator:checkTwoPlace,trigger: 'blur'}],
        address: [{ required: true, message: this.$t('rebate.please_input_withdraw_address'), trigger: 'blur' },
          {validator:checkWithdrawalAddress,trigger: 'blur'}],
        phone_auth_code: [{ required: true, message: this.$t('rebate.please_input_email_auth_code'), trigger: 'blur' }],
      },
    //验证码
      isPhoneSent:true,
      phoneSeconds:60,
      teamPct:'0%',
      invitedPct: '0%',
    //  設置上級
      setSuperiorDialogVisible:false,
      setSurperior:'',
      showInput: false,

      // ruleData: [
      //   {
      //     lv: 'Lv0',
      //     describe: '30天燃料消耗≥30usdt',
      //     directPush: '0%',
      //     interpulsion: '0%'
      //   }, {
      //     lv: 'Lv1',
      //     describe: '3个直推',
      //     directPush: '30%',
      //     interpulsion: '10%'
      //   }, {
      //     lv: 'Lv2',
      //     describe: '5个直推或10个间推 30天燃料消耗≥1000usdt',
      //     directPush: '35%',
      //     interpulsion: '15%'
      //   }, {
      //     lv: 'Lv3',
      //     describe: '15个直推或30个间推 30天燃料消耗≥3000usdt',
      //     directPush: '45%',
      //     interpulsion: '20%'
      //   }, {
      //     lv: 'Lv4',
      //     describe: '40个直推或80个间推 30天燃料消耗≥10000usdt',
      //     directPush: '50%',
      //     interpulsion: '25%'
      //   }, {
      //     lv: 'Lv5',
      //     describe: '100个直推或200个间推 30天燃料消耗≥30000usdt',
      //     directPush: '53%',
      //     interpulsion: '30%'
      //   }, {
      //     lv: 'Lv6',
      //     describe: '300个直推或600个间推 30天燃料消耗≥100000usdt',
      //     directPush: '55%',
      //     interpulsion: '35%'
      //   },
      // ]
      ruleData: [
        {
          lv: 'Lv0',
          describe: '30天燃料消耗≥30usdt',
          directPush: '0%',
          interpulsion: '0%'
        }, {
          lv: 'Lv1',
          describe: '3个直推',
          directPush: '30%',
          interpulsion: '10%'
        }, {
          lv: 'Lv2',
          describe: '5个直推',
          directPush: '35%',
          interpulsion: '15%'
        }, {
          lv: 'Lv3',
          describe: '15个直推',
          directPush: '45%',
          interpulsion: '20%'
        }, {
          lv: 'Lv4',
          describe: '40个直推',
          directPush: '50%',
          interpulsion: '25%'
        }, {
          lv: 'Lv5',
          describe: '100个直推',
          directPush: '55%',
          interpulsion: '30%'
        }, {
          lv: 'Lv6',
          describe: '300个直推',
          directPush: '60%',
          interpulsion: '35%'
        },
      ],
      ruleEconomicQuotient: [
        {
          lv: 'Lv1',
          describe: '30天扣费501-2000U',
          directPush: '15%',
          interpulsion: '0%'
        }, {
          lv: 'Lv2',
          describe: '30天扣费2001-10000U',
          directPush: '20%',
          interpulsion: '15%'
        }, {
          lv: 'Lv3',
          describe: '链下201-500人，30天扣费10001-30000U',
          directPush: '25%',
          interpulsion: '20%'
        }, {
          lv: 'Lv4',
          describe: '链下501-1000人，30天扣费30001-100000U',
          directPush: '30%',
          interpulsion: '25%'
        }, {
          lv: 'Lv5',
          describe: '链下1001人以上，30天扣费大于100000U',
          directPush: '35%',
          interpulsion: '30%'
        },
      ]
    }
  },
  mixins:[topUp,transferMixin],
  components:{
    top_up,
    transfer
  },
  created() {
    this.getRecommendList()
    this.getAwardList()
    this.getCapitalList()
    this.getUserInfo()
    this.changeScreenSize()
  },
  computed:{
    currentDate(){
      let date=new Date()
      return date.getFullYear().toString()+'-'+'0'+(date.getMonth() + 1).toString()+'-'+(date.getDate()<10 ? '0'+date.getDate() :date.getDate() )
    },
    capitalRecord(){
      let topUp=0;let bonus =0;let withDraw=0
      this.capitalList.map(item=>{
        if (item.style==1) topUp++
        if (item.style==2) bonus++
        if (item.style==3) withDraw++
      })
      return `充值${topUp}笔 , 奖金划入${bonus}笔 , 提现${withDraw}笔`
    }
  },
  methods:{
    //获取用户信息
    async getUserInfo(){
      const {data:res}=await getUserInfo()
      this.userInfo=res
      // if (this.userInfo.vip_level===1) this.teamPct='20%'
      // if (this.userInfo.vip_level===2) this.teamPct='30%'
      // if (this.userInfo.vip_level===3) this.teamPct='40%'
      // if (this.userInfo.vip_level===4) this.teamPct='45%'
      // if (this.userInfo.vip_level===5) this.teamPct='50%'
      // if (this.userInfo.vip_level===6) this.teamPct='53%'
      if (this.userInfo.vip_level===1){
        this.teamPct='10%'
        this.invitedPct = '30%'
      }
      if (this.userInfo.vip_level===2){
        this.teamPct='15%'
        this.invitedPct = '35%'
      }
      if (this.userInfo.vip_level===3){
        this.teamPct='20%'
        this.invitedPct = '45%'
      }
      if (this.userInfo.vip_level===4){
        this.teamPct='25%'
        this.invitedPct = '50%'
      }
      if (this.userInfo.vip_level===5){
        this.teamPct='30%'
        this.invitedPct = '55%'
      }
      if (this.userInfo.vip_level===6){
        this.teamPct='35%'
        this.invitedPct = '60%'
      }
    },
    //我的直推
    async getRecommendList(pageNum=1){
      const res= await getRecommendList(pageNum,10)
      if(res.success===false) return this.$message.error(res.message)

      this.recommendListTotal=res.data.totalRow

      var list = res.data.list
      for (let i = 0; i < list.length; i++) {
        list[i].tagLin = ''
        list[i].showInput = false
      }
      this.recommendList=list


      // const table = this.$refs.table// 拿到表格挂载后的真实DOM
      // const divData = table.bodyWrapper// 拿到表格中承载数据的div元素
      // console.log(divData.scrollLeft)
      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      // setInterval(() => {
      //   divData.scrollLeft += 1// 元素自增距离顶部1像素
      //   // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      //   console.log('waiOne'+divData.scrollLeft)
      //   console.log('waiTwo'+divData.clientWidth)
      //   if (divData.scrollLeft+divData.clientWidth == divData.scrollLeft) {
      //     console.log('nei:' + divData.clientWidth+divData.scrollLeft)
      //     divData.scrollLeft --// 重置table距离顶部距离
      //   }
      // }, 1)



    },
    handleRecommendCurrentChange(e){
      this.getRecommendList(e)
    },
    // 本月奖励记录
    async getAwardList(pageNum=1){
      const res= await getAwardList(pageNum,10)
      if(res.success===false) return this.$message.error(res.message)
      this.awardList=res.data.list
      this.awardListTotal=res.data.totalRow
    },
    handleAwardCurrentChange(e){
      this.getAwardList(e)
    },
    //本月资产记录
    async getCapitalList(pageNum=1){
      const res= await getCapitalList(pageNum,10)
      if(res.success===false) return this.$message.error(res.message)
      this.capitalList=res.data.list
      this.capitalListTotal=res.data.totalRow
    },
    handleCapitalCurrentChange(e){
      this.getCapitalList(e)
    },

    // 复制到剪贴板
     handleCopyCode(){
       const input = document.createElement('input');
       document.body.appendChild(input);
       input.setAttribute('value', this.userInfo.invitation);
       input.select();
       if (document.execCommand('copy')) {
         document.execCommand('copy');
         this.$message.success(this.$t('rebate.copy_success'))
       }
       document.body.removeChild(input);
    },
    handleCopyAddress(){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.topUpInfo.base58Address);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },
    handleCopyLink(){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.userInfo.reg_url);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },

  //  生成海报
    async handleCratePoster(){
        this.qr_code_url=window.URL.createObjectURL(await getQrCode())
        this.posterDialogVisible=true
    },
  //  下载推广海报
    handleDownload(){
      this.$html2canvas(document.querySelector("#capture")).then(canvas => {
        document.body.appendChild(canvas)
        var url = canvas.toDataURL({format: 'image/png', quality:1, width:14000, height:4000});
        // console.log(url);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url
        a.download = this.userInfo.invitation;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        document.body.removeChild(canvas);
      });
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = this.qr_code_url
      // console.log(this.userInfo.invitation);
      // a.download = this.userInfo.invitation;
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
    },
  //  转移资产成功
    async handleTransferProperty(){
      if (this.userInfo.commission_balance===0) return this.$message.warning('奖励余额不足 !')
      const confirmResult= await this.$confirm(`您确定要将${this.userInfo.commission_balance}USDT轉帳至我的資產?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).catch(err=>err)
      if (confirmResult!=="confirm")  return this.$message('您点击了取消 !')
        const res=await transferProperty()
      if(!res.success) return this.$message.error(res.message)
      this.$message.success('奖励余额转至资产成功 !')
      this.getUserInfo()
    },
  //  获取充值信息點擊充值按鈕
  //   getTopUpInfo(){
  //     console.log(this.$refs.top_up.getTopUpInfo());
  //   },
    //  发送数字验证码
    async sendAuthCode(kind){
      if (this.userInfo.username===''||this.userInfo.username===null) return this.$message.warning(this.$t('rebate.please_bind_email'))
      if (kind==='phone'){
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(4,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('rebate.auth_code_send_email'))
      }
    },

    showTixian(){
      this.withdrawDialogVisible=true
      this.withdrawForm.money = ''
    },

  //  提现
    async handleWithdraw(e){
      if (e!='confirm') {
        if (this.withdrawForm.money=='') return this.$message.warning(this.$t('rebate.withdraw_money_unable_empty'))
        if (!checkTwoPlaceGeneral(this.withdrawForm.money)) return this.$message.warning(this.$t('rebate.point_two'))
        if (this.withdrawForm.money < 50) return this.$message.warning(this.$t('validate.Minimum_cash_out_amount'))
        if (this.withdrawForm.address=='') return this.$message.warning(this.$t('rebate.withdraw_address_unable_empty'))
        if (!this.checkEmailGeneral(this.withdrawForm.address)) return this.$message.error(this.$t('rebate.email_format_incorrectness'))
        return this.confirmWithdrawDialogVisible = true
      }
      this.$refs.withdrawFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('rebate.withdraw_info_input_error'))
        const {money,address,phone_auth_code}=this.withdrawForm
        const res=await userWithdrawNew(address,money,phone_auth_code)
        if (!res.success) return this.$message.error(res.message)
        this.$message.success(this.$t('rebate.withdraw_apply_sent'))
        this.withdrawDialogVisible=false
        this.confirmWithdrawDialogVisible=false
        this.getUserInfo()
      })
    },
    //点击全部按钮,提现金额全部填入
    handleMoneyAll(){
      this.withdrawForm.money=this.userInfo.asset_balance
    },

    //設置上級
    async handleSetSuperior(){
      if (this.setSurperior=='') return this.$message.warning(this.$t('rebate.please_input_superior_invitation_auth_code'))
      const res= await setSuperior(this.setSurperior)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('rebate.set_success'))
      this.getUserInfo()
    },
    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=1200) {
          this.isMini='medium'
          return
        }
        this.isMini="mini"
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=1200) return this.isMini='medium'
      this.isMini='mini'
    },
    //推广返佣信息
    spreadDialogShow(){},
    spreadDialogShowHandleDownload(){
      this.$html2canvas(document.querySelector("#capture2")).then(canvas => {
        document.body.appendChild(canvas)
        var url = canvas.toDataURL({format: 'image/png', quality:1, width:14000, height:4000});
        // console.log(url);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url
        a.download = this.userInfo.invitation;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        document.body.removeChild(canvas);
      });
    },

    showInputEdit(index, item){
      // for (let i = 0; i < this.recommendList.length; i++) {
      //   if (this.recommendList[i].invitation == invitation){
      //     this.recommendList[i].showInput = true
      //   }
      // }
      item.showInput = true
      item.tagLin = item.tag
      // this.$set(this.recommendList,index, item)
    },

    async onInputBlur(invitation, tagLin, index, item){
      if (tagLin == item.tag) {
        item.showInput = false
        return
      }
      const res = await setRecommendUserTag(invitation, tagLin)
      if (!res) return
      if (res.success){
        item.tag = item.tagLin
        item.showInput = false
        // this.$set(this.recommendList,index, item)
        this.$message.success(this.$t('rebate.set_success'))
      } else {
        this.$message.warning(res.message)
      }

    },
    checkinput(value, name, num) {       // 通过正则过滤小数点后两位
      let inputValue =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              // .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || "" // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      if (parseInt(inputValue) > 9007199254740991) {
        inputValue = 9007199254740991
      }
      this.withdrawForm.money = inputValue
    },

    checkEmailGeneral(value){
      if (value.substr(0,1) != 'T' || value.length>34 || value.length<34) return false
      return true
    }
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector('input').focus()
      }
    }
  },

}
</script>

<style scoped>
.invite{
  padding: 48px 0 48px  140px ;
  background: #F7F9FC;
  position: relative;
}
.invite .title{
  font-size: 44px;
  font-weight: 500;
  color: #1E2329;
}
.invite .title-sub{
  font-size: 20px;
  font-weight: 400;
  color: #72777E;
  margin-bottom: 72px;
  margin-top: 12px;
}
/*       grade        */
.invite .grade{
  margin-bottom: 25px;
}
.invite .grade>div{
  display: inline-block;
  vertical-align: top;
}
.invite .grade>div:first-child{
  font-size: 16px;
  font-weight: 400;
  color: #1E2329;
  margin-right: 8px;
}
/*.invite .grade>div:nth-child(2){*/
/*  width: 76px;*/
/*  height: 22px;*/
/*  font-size: 13px;*/
/*  font-weight: 500;*/
/*  box-sizing: border-box;*/
/*  padding-right: 8px;*/
/*  color: #E3B936;*/
/*  line-height: 22px;*/
/*  text-align: right;*/
/*  background: url("../../assets/img/rebate/vip.svg")  ;*/
/*  background-size:76px 22px;*/
/*}*/
.invite .grade>div:nth-child(3){
  font-size: 16px;
  font-weight: 400;
  color: #1E2329;
  margin-left: 40px;
  margin-right: 8px;
}
.invite .grade>div:nth-child(4){
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
}
/*         extend        */
.invite .extend{
  padding-top: 24px;
  /*border-top: 1px #EAECEF solid;*/
}
.invite .extend>div:first-child{
  font-size: 16px;
  font-weight: 400;
  color: #1E2329;
  margin-bottom: 16px;
}

.invite .extend>div:first-child span{
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
  margin-right: 16px;
}
.invite .extend>div:nth-child(2){
  font-size: 16px;
  font-weight: 400;
  color: #1E2329;
  margin-bottom: 24px;
}
.invite .extend>div:nth-child(2) div{
  display: inline-block;
  vertical-align: top;
}
.invite .extend>div:nth-child(2) span{
   font-size: 16px;
   font-weight: 400;
   color: #72777E;
  margin-right: 16px ;
 }
.invite .extend>div:nth-child(3)>span:first-child{
  display: inline-block;
  width: 350px;
  height: 48px;
  background: #AB54DB;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #F3F7FF;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.invite .extend>div:nth-child(3)>span:nth-child(2){
  display: inline-block;
  height: 48px;
  vertical-align: top;
  box-sizing: border-box;
  width: 48px;
  border: 8px solid #FFFFFF;
  margin-left: 35px;
  cursor: pointer;
}
/*picture*/
.invite .picture{
  position: absolute;
  top: 65px;
  right: 8%;
}
.invite .picture img{
  width: 340px;
  height: 360px;
}

/*适配代码*/
@media screen and (max-width: 1204px) {
  .invite .picture img{
    width: 238px;
    height: 271px;
  }
  .invite .picture{
    right: 4%;
  }
}
@media screen and (max-width: 985px) {
  .invite .picture img{
    width: 190px;
    height: 217px;
  }
}
@media screen and (max-width: 800px){
  .invite{
    padding: 36px 16px 48px  16px ;
    background: #F7F9FC;
    position: relative;
  }
  .invite .picture img{
    width: 159px;
    height: 181px;
  }
  .invite .title{
    font-size: 22px;
    font-weight: 500;
  }
  .invite .title-sub{
    font-weight: 400;
    color: #72777E;
    margin-bottom: 31px;
    margin-top: 6px;
    font-size: 14px;
  }

  .invite .grade{
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    width: 100px;
  }
  .invite .grade>div{
    display: inline-block;
    vertical-align: top;
  }
  .invite .grade>div:first-child{
    font-size: 14px;
    font-weight: 400;
    color: #1E2329;
    margin-bottom: 8px;
  }
  .invite .grade>div:nth-child(2){
    font-size: 12px;
  }
  .invite .grade>div:nth-child(3){
    font-size: 14px;
    margin:26px 0 8px 0;
  }
  .invite .grade>div:nth-child(4){
    font-size: 14px;
    font-weight: 400;
    color: #72777E;
  }

  .invite .extend{
    padding-top: 29px;
    border-top: 1px #EAECEF solid;
  }
  .invite .extend>div:first-child{
    font-size: 14px;
    margin-bottom: 17px;
  }
  .invite .extend>div:first-child span{
    font-size: 14px;
    margin-right: 20px;
  }
  .invite .extend>div:nth-child(2){
    font-size: 14px;
  }
  .invite .extend>div:nth-child(2) span{
    display: inline-block;
    font-size: 14px;
    margin-right: 5px ;
    width: 300px;
  }
  .invite .extend>div:nth-child(3)>span:first-child{
    display: inline-block;
    width: 280px;
    height: 40px;
    background: #AB54DB;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 400;
    color: #F3F7FF;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .invite .extend>div:nth-child(3)>span:nth-child(2){
    display: inline-block;
    height: 40px;
    vertical-align: top;
    box-sizing: border-box;
    width: 40px;
    border: 6px solid #FFFFFF;
    margin-left: 8px;
  }
  .invite .extend>div:nth-child(3)>span:nth-child(2) img{
    width: 27px !important;
    height: 27px !important;
  }
}


/*        icons功能部分      */
.icons{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 48px 8% 0 8%;
}
.icons>div{
  margin-bottom: 48px;
  width: 282px;
  height: 212px;
  padding: 24px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 0 6px 0 rgba(0, 0, 0, 0.01);
  border-radius: 2px;
  border: 1px solid #EAECEF;
}
.icons>div>div:first-child{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.icons>div>div:first-child img{
  width: 50px;
  height: 50px;
}
.icons>div>div:first-child>span{
  font-size: 20px;
  font-weight: 500;
  color: #1E2329;
  margin-left: 12px;
}
.icons>div>div:nth-child(2){
  font-size: 18px;
  font-weight: 400;
  color: #1E2329;
}
/*icons中的first*/
.icons>div:first-child>div:nth-child(2)>span{
  font-weight: 600;
  color: #E3B936;
}
.icons>div:first-child>div:nth-child(2)>span:nth-child(1){
  margin-right: 32px;
}

/*icons的second至end*/
.icons>div:not(:nth-child(1))>div:nth-child(2)>span{
  font-size: 18px;
  font-weight: 600;
  color: #E3B936;
  margin-right: 15px;
}
/*icons的1 2 4*/
/*.icons>div:not(:nth-child(3))>div:nth-child(3){*/
/*  margin-top: 24px;*/
/*  width: 100%;*/
/*  height: 40px;*/
/*  !*box-sizing: border-box;*!*/
/*  border-radius: 2px;*/
/*  border: 1px solid #AB54DB;*/
/*  text-align: center;*/
/*  line-height: 40px;*/
/*  font-size: 16px;*/
/*  font-weight: 400;*/
/*  color: #AB54DB;*/
/*}*/
/*icons的3*/
.icons>div:nth-child(3)>div:nth-child(3),.icons>div:not(:nth-child(3))>div:nth-child(3){
  display: flex;
  justify-content: space-around;
}
.icons>div:nth-child(3)>div:nth-child(3)>span,.icons>div:not(:nth-child(3))>div:nth-child(3)>span{
  width: 130px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #AB54DB;
  display: inline-block;
  margin-top: 24px;
  /*box-sizing: border-box;*/
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #AB54DB;
}
.icons>div:not(:nth-child(4))>div:nth-child(3)>span:first-child{
  margin-right: 20px;
}
.icons>div:nth-child(3)>div:nth-child(3){
    margin-top: 24px;
    width: 100%;
    height: 40px;
    /*box-sizing: border-box;*/
    border-radius: 2px;
    border: 1px solid #AB54DB;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #AB54DB;
}
.icons>div:nth-child(4)>div:nth-child(3) {
  margin-top: 24px;
}
/*icons的鼠标悬浮*/
.icons>div:nth-child(3)>div:nth-child(3):hover,.icons>div:not(:nth-child(3))>div:nth-child(3)>span:hover{
  cursor: pointer;
}
.icons>div:nth-child(3)>div:nth-child(3):hover,.icons>div:not(:nth-child(3))>div:nth-child(3)>span:hover{
  background: #AB54DB;
  color: #FFFFFF;
}

/*    icons  适配  */
@media screen and (max-width: 690px){
  .icons{
    justify-content: center;
    padding: 20px 20px 0;
    margin-bottom: 0px;
  }
  .icons>div{
    width: 100%;
    margin-bottom: 20px;
  }
  .icons>div:nth-child(3)>div:nth-child(3){
    display: flex;
    justify-content: space-around;
  }
}

/*              recommend               */
.recommend,.award,.capital{
  margin:0  8% 48px 8%;
  padding: 0 32px;
  border: 1px solid #DADDE1;
}
.recommend .title>img,.award .title>img,.capital .title>img{
  width: 32px;
  height: 32px;
}
.recommend .title,.award .title,.capital .title{
  height: 72px;
  background: rgba(243, 247, 255, 0.3);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.recommend .title>span:nth-child(2),.award .title>span:nth-child(2) ,.capital .title>span:nth-child(2){
  margin-left: 8px;
  font-size: 22px;
  font-weight: 500;
  color: #1E2329;
}
.recommend .title>span:nth-child(3),.award .title>span:nth-child(3) ,.capital .title>span:nth-child(3){
  font-size: 18px;
  font-weight: 400;
  margin-left: 24px;
  padding-left: 24px;
  color: #72777E;
  border-left: 1px solid rgba(114, 119, 126, 0.3);
}

@media screen and (max-width: 800px){
  .recommend,.award,.capital{
    margin:0  20px 12px;
    border: 1px solid #DADDE1;
    padding: 0;
  }
  .recommend .title>img,.award .title>img,.capital .title>img{
    width: 20px;
    height: 20px;
    margin-left: 9px;
  }
  .recommend .title,.award .title,.capital .title{
    height: 44px;
  }
  .recommend .title>span:nth-child(2),.award .title>span:nth-child(2) ,.capital .title>span:nth-child(2){
    margin-left: 8px;
    font-size: 16px;
    color: #1E2329;
  }
  .recommend .title>span:nth-child(3),.award .title>span:nth-child(3) ,.capital .title>span:nth-child(3){
    font-size: 14px;
    font-weight: 400;
    margin-left: 24px;
    padding-left: 24px;
    color: #72777E;
    border-left: 1px solid rgba(114, 119, 126, 0.3);
  }
  .el-pagination{
    margin: 14px 0 !important;
    padding: 0;
  }
  /deep/ .el-pagination ul li{
    min-width: 30px;
  }
}

/*充值的对话框*/
/deep/ .topUpDialog .el-dialog__body {
  padding:15px 26px !important;
}
.topUp>div:first-child{
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(2){
  margin: 8px 0 24px 0px;
}
.topUp>div:nth-child(3){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(4) img{
  width: 136px;
  height: 136px;
  display: inline-block;
  margin: 8px 0 16px 0;
}
.topUp>div:nth-child(5){
  padding: 11px 0 24px 0;
  border-top: 1px solid #EAECEF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topUp>div:nth-child(5)>span{
  font-size: 12px;
  color: #1E2329;
}
.topUp>div:nth-child(5)>img{
  display: inline-block;
}
.topUp>div:nth-child(6){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(7)>ul{
  margin: 8px 0 0 0 ;
  padding-left: 20px;
}
.topUp>div:nth-child(7)>ul>li{
  font-size: 14px;
  color: #1E2329;
  line-height: 25px;
}
.topUp>div:nth-child(7)>ul>li:nth-child(2){
  color:red;
}
.container{
  min-height: 2000px;
}
@media screen  and (max-width: 900px){
  .topUp>div:nth-child(4) img{
    width: 100px;
    height: 100px;
    margin: 8px 0 8px 0;
  }
  .topUp>div:nth-child(5){
    padding: 10px 0 11px 0;
  }
  /deep/ .topUpDialog .el-dialog__body {
    padding:5px 20px !important;
  }
  /deep/ .topUpDialog .el-form-item {
    margin-bottom: 8px;
  }
}

/*验证码*/
.auth-code{
  position: absolute;
  top:42px;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  margin-right: 6px;
}

/*@media screen and (min-width: 1700px){*/
/*  .icons>div{*/
/*    width: 322px;*/
/*  }*/
/*}*/
/*适配icons*/
/*@media screen and (min-width: 1265px) and (max-width: 1420px) {*/
/*  .icons>div{*/
/*    width: 500px;*/
/*  }*/
/*  .icons>div:nth-child(3)>div:nth-child(3)>span{*/
/*    width: 50%;*/
/*  }*/
/*}*/
@media screen and (min-width: 980px) and (max-width: 1265px) {
  .icons>div{
    width: 460px;
  }
  .icons>div:nth-child(3)>div:nth-child(3)>span{
    width: 50%;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1205px) {
  .icons>div{
    width: 430px;
  }
  .icons>div:nth-child(3)>div:nth-child(3)>span{
    width: 50%;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .container{
    min-height: 2000px;
  }
  .icons>div{
    width: 400px;
  }
  .icons>div:nth-child(3)>div:nth-child(3)>span{
    width: 50%;
  }
}
@media screen and (min-width: 816px) and (max-width: 1000px) {
  .icons>div{
    width: 330px;
  }
  .icons>div:nth-child(3)>div:nth-child(3)>span{
    width: 50%;
  }
}

@media screen  and (min-width: 800px){
  .border-rebate{
    width: 600px;
    border-bottom: 1px #EAECEF solid
  }

}
.current-date{
  border: none !important;
  color:#72777E !important;
  font-size: 16px !important;
  text-align: left !important;
  cursor: auto !important;
}
.current-date:hover{
  background: none !important;
  color:#72777E !important;
}


@media screen  and (min-width:1265px) {
  .outer{
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .invite , .outer>div{
    width: 1200px;
  }
  .icons{
    flex-wrap: nowrap;
    padding: 48px 0 0 0;
  }
  .invite{
    padding: 48px 0 48px 0;
  }
  .recommend,.award,.capital{
    margin:0  0 48px 0;
  }
}
.el-pagination{
  margin-bottom:  24px !important;
  padding: 0;
}
.el-table{
  margin-bottom: 24px
}
 /deep/.poster-img>div{
   background: #AB54DB;
}
 /deep/.poster-img .el-dialog .el-dialog__body{
    padding-top: 0;
 }
 #btn-down:hover{
   background: #AB54DB;
   color: #F3F7FF !important;
 }

 @media screen and (max-width: 900px) {
   /deep/.poster-img .el-dialog__header {
     padding: 0px ;
   }
   /deep/.poster-img>div {
     max-height:100%;
   }
   /deep/.poster-img .el-dialog__body{
     padding: 20px  !important;
   }
 }

.tixian{
  margin: 0 10px;
}

#topUpBtn{
  color: rgba(255, 255, 255, 1);
  background: rgba(44, 117, 255, 1);
}
#topUpBtn:hover{
  opacity: 0.8;
}
#topUpBtn1{
  color: rgba(255, 255, 255, 1);
  background: rgba(44, 117, 255, 1);
  margin-right: 0;
}
#topUpBtn1:hover{
  opacity: 0.8;
}

.lv {
  position: relative;
  margin-top: 4px;
  background-image: url(../../assets/img/rebate/level_new1.svg);
  background-repeat: round;
  width: 45px;
  height: 16px;
}

 >>>.spreadDialogVisible .el-dialog__body{
   padding: 20px !important;
   border: 0 !important;
   background: #FFFFFF;
   box-sizing: border-box;

}
>>>.spreadDialogVisible .el-dialog__header{
  padding: 0;
}

.spreadDialogVisible >>>.el-table th.el-table__cell.is-leaf, .el-table td.el-table__cell{
  text-align: center;
  /*background: rgba(44, 117, 255, 0.05);*/
}
.spreadDialogVisible >>>.el-table--enable-row-transition .el-table__body td.el-table__cell{
  text-align: center;
}
.spreadDialogVisible >>>.el-dialog__headerbtn{
  font-size: 20px;
}

.spreadDialogVisible >>>.el-table .el-table__cell{
  padding: 6px 0;
}

.spreadDialogVisible >>>.el-table{
  margin-bottom: 10px !important;
}
</style>
